.footer {
    background-color: black;
    color: white;
    display: grid;
    grid-template-columns: repeat(4, 25%);
    grid-gap: 40px;
    overflow: hidden;
    width: 100%;
    padding: 65px 100px;
}

.footer a {
    color: white;
}

.footer .content svg {
    margin-bottom: 30px;
}

.footer .content svg+p {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 30px;
}

.footer h5 {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 25px;
}

.footer ul li {
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.1s linear;
}

.footer ul li :hover {
    opacity: 0.8;
}

.footer ul li svg {
    margin-right: 12px;
}


@media screen and (max-width:1100px) {
    .footer {
       display: flex;
       flex-wrap: wrap;
       padding: 65px 50px;
    }
    .footer .content{
        width: 100%;
    }
    .footer .subs,.footer .contacts, .footer .media{
        width: 30%;
    }
}

@media screen and (max-width:996px) {}

@media screen and (max-width:769px) {
    .footer {
        display: flex;
        flex-wrap: wrap;
        padding: 65px 20px;
     }
     .footer .content{
         width: 100%;
     }
     .footer .subs,.footer .contacts, .footer .media{
         width: 100%;
     }
}