@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&family=Roboto:wght@100;300&display=swap");


.successWrapper * {
    box-sizing: border-box;
    transform-style: preserve-3d;
}

@property --swing-x {
    initial-value: 0;
    inherits: false;
    syntax: '<integer>';
}

@property --swing-y {
    initial-value: 0;
    inherits: false;
    syntax: '<integer>';
}

.successWrapper {
    min-height: 100vh;
    display: flex;
    font-family: 'Roboto', sans-serif;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #737373;
    color: white;
    perspective: 1200px;
}

.successWrapper a {
    text-transform: uppercase;
    text-decoration: none;
    background: linear-gradient(98.41deg, #E8C488 0%, #8D6736 100%);
    color: white;
    padding: 1rem 4rem;
    border-radius: 4rem;
    font-size: 0.875rem;
    letter-spacing: 0.05rem;
}

.successWrapper p {
    font-weight: 100;
}

.successWrapper h1 {
    -webkit-animation: swing 2s infinite alternate ease-in-out;
    animation: swing 2s infinite alternate ease-in-out;
    font-size: clamp(5rem, 40vmin, 20rem);
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    margin-bottom: 1rem;
    letter-spacing: 1rem;
    transform: translate3d(0, 0, 0vmin);
    --x: calc(50% + (var(--swing-x) * 0.5) * 1%);
    background: radial-gradient(#e6e6e6, #7a7a7a 45%) var(--x) 100%/200% 200%;
    -webkit-background-clip: text;
    /* color: transparent; */
    opacity: 0.6;
}

.successWrapper h1:after {
    -webkit-animation: swing 2s infinite alternate ease-in-out;
    animation: swing 2s infinite alternate ease-in-out;
    content: "\01F60E";
    position: absolute;
    top: 0;
    left: 0;
    color: #000;
    filter: blur(1.6vmin) grayscale(100%);
    z-index: -1;
    transform: scale(1.05) translate3d(0, 12%, -10vmin) translate(calc((var(--swing-x, 0) * 0.05) * 1%), calc((var(--swing-y) * 0.05) * 1%));
}

.successWrapper .cloak {
    animation: swing 2s infinite alternate-reverse ease-in-out;
    height: 100%;
    width: 100%;
    transform-origin: 50% 30%;
    transform: rotate(calc(var(--swing-x) * -0.25deg));
    background: radial-gradient(40% 40% at 50% 42%, transparent, #000 35%);
}

.successWrapper .cloak__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
}

.successWrapper .cloak__container {
    height: 250vmax;
    width: 250vmax;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.successWrapper .info {
    text-align: center;
    line-height: 1.5;
    max-width: clamp(16rem, 90vmin, 25rem);
}

.successWrapper .info>p {
    margin-bottom: 3rem;
}

@-webkit-keyframes swing {
    0% {
        --swing-x: -100;
        --swing-y: -100;
    }

    50% {
        --swing-y: 0;
    }

    100% {
        --swing-y: -100;
        --swing-x: 100;
    }
}

@keyframes swing {
    0% {
        --swing-x: -100;
        --swing-y: -100;
    }

    50% {
        --swing-y: 0;
    }

    100% {
        --swing-y: -100;
        --swing-x: 100;
    }
}