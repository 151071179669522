/* GENERAL CODES */

:root {
  --main: linear-gradient(98.41deg, #E8C488 0%, #8D6736 100%);
  --bg-transition: linear-gradient(98.41deg, black 0%, #E8C488 100%);
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.d-none {
  display: none;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}
.flex-column{
  flex-direction: column;
}

.pointer {
  cursor: pointer;
}

.Mui-focused {
  border: none !important;
}

.mobileViewOnly {
  display: none !important;
}

.tabViewOnly {
  display: none !important;
}

.v-hidden {
  visibility: hidden;
}

.default {
  /* all: unset !important; */
  visibility: visible !important;
  height: auto !important;
  margin: auto !important;
  padding: auto !important;
}

input[type="radio"] {
  -webkit-appearance: radio;
}

.upButton {
  border-radius: 8px;
  padding: 10px;
  display: block;
  background: var(--main) !important;
  color: white;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .upButton img {
  animation: bounce 2s infinite ease-in-out;
}

@keyframes bounce {

  0%,
  100% {
    transform: translateX(-50%) translateY(0);
  }

  50% {
    transform: translateX(-50%) translateY(-5px);
  }
} */

.privacy-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--bg-transition);
  z-index: 99;
}

.example_box {
  display: block !important;
  width: 200px !important;
  height: 200px !important;
  background-color: black;
  padding: 100px !important;
}

.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #0f0f0f;
  transform-origin: bottom;
  z-index: 9999;
}

.slide-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #0f0f0f;
  transform-origin: top;
  z-index: 9999;
}

@media screen and (max-width:900px) {
  .tabViewOnly {
    display: block !important;
  }

  .tabViewNone {
    display: none !important;
  }
}

@media screen and (max-width:769px) {
  .mobileViewOnly {
    display: block !important;
  }

  .mobileViewNone {
    display: none !important;
  }
}