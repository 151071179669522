.sidebarSubscription {
    height: 100vh;
}

.navbar_subscribe {
    padding: 35px 0px 35px 100px;
    background-color: black;
    color: white;
}

.navbar_subscribe svg rect {
    width: 133px !important;
    height: 40px;
}

.navbar_subscribe .profileImg {
    width: 60px;
    height: 60px;
    margin: 0 15px 0 30px;
    border-radius: 50%;
    object-fit: cover;
}

.navbar_subscribe p {
    max-width: 122px;
    font-weight: 700;
    font-size: 16px;
}

.sub_content {
    height: 100%;
    background-image: url("../../assets/images/sidebarbg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.sub_content .shadowBox {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.containerSub {
    width: 100%;
    padding: 25px 50px 0 100px;
}

.containerSub .item {
    background-color: white;
    margin-bottom: 25px;
    border-radius: 10px;
    padding: 25px;
    cursor: pointer;
    transition: all 0.4s linear;
    animation: beat 1s linear infinite;
}

.containerSub .item:hover {
    background-color:ivory;
    border-radius: 0;
    animation-play-state: paused;
    rotate: 0deg !important;
}
.containerSub .item:first-child{
    animation-delay: 0.4s;
}
.containerSub .item:hover p{
    height: auto !important;
    visibility: visible;
    margin-top: 20px;

}
/*  */

@keyframes beat {
    0% {
        rotate: 0deg;
    }

    33% {
        rotate: 2deg;
    }

    66% {
        rotate: -2deg;
    }
    100% {
        rotate: 0deg;
    }
}

/*  */

.containerSub .item button {
    margin: 0 0 0 30px;
    padding: 8px 16px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;
    text-transform: unset;
    letter-spacing: 1px;
}

.containerSub .pro {
    background: linear-gradient(98.41deg, #E8C488 0%, #8D6736 100%);
}

.containerSub .simple {
    background: linear-gradient(276.07deg, #303030 0%, rgba(48, 48, 48, 0.4) 100%);
}

.containerSub p {
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #303030;
    opacity: 0.7;
    margin-top: 0px;
    height: 0 !important;
    visibility: hidden;
    transition: 0.2s linear;
}

@media screen and (max-width:1300px) {
    .navbar_subscribe {
        padding: 35px 0px 35px 50px;
    }

    .navbar_subscribe svg rect {
        min-width: 68px !important;
    }

    .navbar_subscribe .profileImg {
        width: 30px;
        height: 30px;
        margin: 0 5px 0 15px;
    }

    .navbar_subscribe p {
        font-size: 12px;
    }

    .containerSub {
        padding: 25px 25px 0 50px;
    }


}

@media screen and (max-width:996px) {
    .navbar_subscribe {
        padding: 35px 0px 35px 30px;

    }

    .containerSub {
        width: 100%;
        padding: 25px 20px 0 30px;
    }

    .containerSub p {
        font-size: 12px;
    }

}

@media screen and (max-width:769px) {
    .navbar_subscribe {
        padding: 35px 20px 35px 20px;
        justify-content: space-between;
    }

    .containerSub {
        width: 100%;
        padding: 25px 20px 0 20px;
    }

}