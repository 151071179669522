.carouselWrapper{
    padding: 70px;
    background: var(--main);
    position: relative;
}
.carouselWrapper::before{
    content: "";
    display: block;
    width: 70px;
    height: 70px;
    background-color:#383838;
    rotate: 45deg;
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translate(-50%);
}
.carouselWrapper .heading {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    color: #303030;
    margin: 60px 0 30px;
}

.carouselWrapper .slide:hover {
    position: relative;
}

.carouselWrapper .slide .popupBox {
    position: absolute;
    top: 0;
    left: 5px;
    bottom: 5px;
    right: 5px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
    opacity: 0;
    transition: all 0.2s linear;
    display: flex;
    justify-content: center;
    align-items: center;
}
.carouselWrapper .slide .popupBox .title{
    font-size: 24px;
    font-weight: 700;
    margin: 35px 0;
    
}
.carouselWrapper .slide .popupBox .description_portfolio{
    font-size: 16px;
    font-weight: 600;
    margin: 0 35px;
}
.carouselWrapper .slide:hover .popupBox {
    visibility: visible;
    top: 0px;
    opacity: 1;

}
.carouselWrapper .portfolioLink{
    display: block;
    width: 200px;
    margin: 15px auto;
    padding: 8px 12px;
    border: 1px solid white;
    color: white;
    transition: all 0.3s linear;
}
.carouselWrapper .portfolioLink:hover{
    margin-top: 16px;
    background-color: white;
    color: black;
}

.carousel-main {
    color: white;
    position: relative !important;
}

.horizontalSlider___281Ls.carousel__slider {
    width: 90%;
    margin: auto;
    height: 502px;
    position: relative !important;

}

.carouselWrapper .description {
    display: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute !important;
}

.horizontalSlider___281Ls.carousel__slider:hover .carouselWrapper .description {
    display: block;
}

.forEdit .horizontalSlider___281Ls.carousel__slider {
    height: 350px !important;
}

.carousel__back-button,
.carousel__next-button {
    position: absolute;
    top: 0;
    bottom: 10px;
    /* transform: translateY(-50%); */
    border: none;
    background-color: transparent;
    width: 5%;
    display: flex;
    align-items: center;
}

.carousel__back-button {
    left: 0;
    padding-left: 0px;
    justify-content: start;
}

.carousel__next-button {
    right: 0;
    padding-right: 0;
    justify-content: end;
}

.carousel__slide {
    padding-bottom: 0 !important;
    height: 510px;
}


.carousel__slide img {
    object-fit: contain;
    object-position: top;
}

.forEdit .carousel__slide {
    height: 360px;
    padding-bottom: 0 !important;
}

.carousel__dot-group {
    text-align: center;
    margin-bottom: 60px;
}

.carousel__dot-group button {
    width: 18px;
    height: 6px;
    border-radius: 2px;
    border: none;
    margin: 0 5px;
    background-color: #fff;
    transition: all 0.1s linear;
}

.carousel__dot--selected {
    background-color: black !important;
    width: 40px !important;
    background: black;
}



@media screen and (max-width:1300px) {
    .horizontalSlider___281Ls.carousel__slider {
        height: 400px;
    }

    .carousel__slide {
        height: 410px;
    }
}

@media screen and (max-width:996px) {}

@media screen and (max-width:769px) {
    .horizontalSlider___281Ls.carousel__slider {
        height: 200px;
        width: 85%;
    }

    .carousel__slide {
        height: 210px;
    }

    .forEdit .horizontalSlider___281Ls.carousel__slider {
        height: 200px !important;
        width: 85%;
    }

    .forEdit .carousel__slide {
        height: 210px !important;
    }
    .carouselWrapper .slide .popupBox{
        position: fixed !important;
        left: 0;
        top: 0;
        height: auto;
    }
    
}