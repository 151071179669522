

.myProfile .bg-black {
    background-color: #383838;
    padding: 0 80px 90px 80px;
    position: relative;
    text-align: center;
}

.myProfile .bg-black::after {
    content: "";
    display: block;
    width: 700px;
    height: 100px;
    background-color: green;
    position: absolute;
    top: -45px;
    left: -50px;
    transform: rotate(10deg);
    background-color: #383838;
    z-index: 1;

}

.myProfile .bg-black::before {
    content: "";
    display: block;
    width: 700px;
    height: 100px;
    background-color: blue;
    position: absolute;
    top: -45px;
    right: -50px;
    transform: rotate(-10deg);
    background-color: #383838;
    z-index: 1;

}



.myProfile .media li {
    color: white;
    padding: 0 30px;
}

.myProfile .media li:hover .circle {
    scale: 1.1;
    background: transparent;
}

.myProfile .media li:hover .circle::after {

    background: var(--main);
}

.myProfile .media li:hover .circle svg {
    filter: grayscale(100%);
}



.myProfile .media li .circle::after {
    background-color: #383838;
}

/* Finished here */

.myProfile .description {
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #FFFFFF;
    word-wrap: break-word;
    width: 70%;
    margin: 0 auto 50px;
}

.myProfile .share {
    width: 50%;
    margin: 40px auto;
    text-align: center;
}

.myProfile .share h6 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin-bottom: 30px;
}

.myProfile .share .item {
    margin: 0 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

.myProfile .share .item .iconBox {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    background-color: rebeccapurple;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.myProfile .share .item .iconBox svg {
    width: 28px;
    height: 28px;
    color: white;
}

.myProfile .share .item .iconBox.orange {
    background-color: #BB792B;
}

.myProfile .share .item .iconBox.blue {
    background-color: #344677;
}

.myProfile .share .item .iconBox.lightBlue {
    background-color: #5FB4ED;
}

.myProfile .share .item .iconBox.red {
    background-color: #992C1D;
}

.myProfile .share .item .iconBox+p {
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    color: #303030;
}

.link-text {
    margin: 40px 0 !important;
    padding: 12px 0 !important;
    text-align: center;
    width: 100%;
    border-top: 2px solid gray !important;
}

.link-text span {
    display: block;
    text-decoration: underline !important;
    color: blue !important;
    cursor: pointer;
}


@media screen and (max-width:1300px) {
    .myProfile {
        padding: 0px 50px;
    }

    .myProfile .wrapper {
        padding: 0px 0px;
        width: 100%;
    }

    .myProfile .media li {
        padding: 0 25px;
    }
}

@media screen and (max-width:996px) {
    .myProfile {
        padding: 0px 0px;
    }

    .myProfile .sectionBg {
        height: 360px;
    }

    .myProfile .imgBox img {
        width: 200px;
        height: 200px;
    }

    .myProfile .name {
        font-size: 38px;
    }

    .myProfile .profession {
        font-size: 24px;
    }

    .myProfile .saveProfile {
        font-size: 24px !important;
    }

    .myProfile .media li {
        padding: 0 18px;
    }
}

@media screen and (max-width:769px) {
    .myProfile {
        padding: 0px 0px;
    }

    .myProfile .sectionBg {
        height: 360px;
        background-size: cover !important;
        background-position: center !important;
        background-position: center !important;
        background-attachment: unset !important;
    }

    .myProfile .imgBox {
        min-height: 50px;
    }

    .myProfile .imgBox img {
        width: 160px;
        height: 160px;
    }

    .myProfile .name {
        font-size: 24px;
    }

    .myProfile .profession {
        font-size: 16px;
    }

    .headingButtons {
        font-size: 20px !important;
        padding: 10px 6px !important;
        line-height: 110% !important;
    }

    .myProfile .media {
        display: grid;
        grid-template-columns: repeat(3, auto);
        justify-content: unset !important;
    }

    .myProfile .bg-black {
        padding: 0 30px 90px 30px;
    }

    .myProfile .media {
        margin: 0 20px;
    }

    .myProfile .media li {
        margin-bottom: 30px;
        padding: 0px;
    }

    .myProfile .media li .circle {
        width: 60px;
        height: 60px;
    }

    .myProfile .media li .circle::after {
        width: 56px;
        height: 56px;
    }

    .myProfile .media li .circle svg {
        width: 24px;
        height: 24px;
    }

    .myProfile .media p {
        font-size: 14px;
        font-weight: 600;
    }

    .myProfile .share {
        width: 100%;
        padding: 0 20px;
    }

    .myProfile .share h6 {
        font-size: 20px;
    }

    .myProfile .share .item {
        margin: 0 10px;
    }

    .myProfile .share .item .iconBox {
        width: 36px;
        height: 36px;

    }

    .myProfile .share .item .iconBox svg {
        width: 25px;
        height: 25px;
        color: white;
    }

    .myProfile .share .item .iconBox+p {
        font-size: 16px;
    }

    .myProfile .typingAnimation {
        font-size: 3em;
    }
}