.myProfile.v2 .wrapper-child {
    -webkit-box-shadow: 0px 0px 49px -4px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 49px -4px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 49px -4px rgba(66, 68, 90, 1);
}

.myProfile.v2 .accordionProfile {
    -webkit-box-shadow: 0px 0px 49px -4px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 49px -4px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 49px -4px rgba(66, 68, 90, 1);
}

.myProfile.v2 .bg-black {
    background-color: #fff;
    padding: 0 80px 90px 80px;
    position: relative;
    text-align: center;
    background-image: url("../../../assets/images/template2-bg.jpg");
    background-repeat: no-repeat;
    background-position: end;
    background-size: cover;
    box-shadow: inset 0 0 0 1000px rgba(255,255,255,.7);

}

.myProfile.v2 .bg-black::after {
    content: "";
    display: block;
    width: 700px;
    height: 100px;
    position: absolute;
    top: -45px;
    left: -50px;
    transform: rotate(10deg);
    background-color: #fff;
    z-index: 1;
    box-shadow: inset 0 0 0 1000px rgba(255,255,255,.7);
}

.myProfile.v2 .bg-black::before {
    content: "";
    display: block;
    width: 700px;
    height: 100px;
    position: absolute;
    top: -45px;
    right: -50px;
    transform: rotate(-10deg);
    background-color: #fff;
    z-index: 1;
    box-shadow: inset 0 0 0 1000px rgba(255,255,255,.7);
}


.myProfile.v2 .name {
    color: #000;
}

.myProfile.v2 .profession {
    color: #000;
}

.myProfile.v2 .media li {
    color: #000;
    padding: 0 30px;
}

.myProfile.v2 .media li:hover .circle {
    scale: 1.1;
    background: transparent;
}

.myProfile.v2 .media li:hover .circle::after {
    background: var(--main);
}

.myProfile.v2 .media li:hover .circle svg{
    filter: grayscale(50%) !important;
}

.myProfile.v2 .media li a {
    color: #000;
}



.myProfile.v2 .media li .circle::after {
    background-color: #fff;
}

.myProfile.v2 .media p {
    color: #000;
}
.myProfile.v2 .typingText {
    background-color: rgba(0, 0, 0, 0.6);
}

.myProfile.v2 .saveProfile {
    color: #000;
}

.myProfile.v2 .aboutMe {
    color: #000;
}
/* Finished here */


.myProfile.v2 .description {
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #000;
    word-wrap: break-word;
    width: 70%;
    margin: 0 auto 50px;
}

.myProfile.v2 .share {
    width: 50%;
    margin: 40px auto;
    text-align: center;
}

.myProfile.v2 .share h6 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin-bottom: 30px;
}

.myProfile.v2 .share .item {
    margin: 0 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

.myProfile.v2 .share .item .iconBox {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    background-color: rebeccapurple;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.myProfile.v2 .share .item .iconBox svg {
    width: 28px;
    height: 28px;
    color: white;
}

.myProfile.v2 .share .item .iconBox.orange {
    background-color: #BB792B;
}

.myProfile.v2 .share .item .iconBox.blue {
    background-color: #344677;
}

.myProfile.v2 .share .item .iconBox.lightBlue {
    background-color: #5FB4ED;
}

.myProfile.v2 .share .item .iconBox.red {
    background-color: #992C1D;
}

.myProfile.v2 .share .item .iconBox+p {
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    color: #303030;
}

.link-text {
    margin: 40px 0 !important;
    padding: 12px 0 !important;
    text-align: center;
    width: 100%;
    border-top: 2px solid gray !important;
}

.link-text span {
    display: block;
    text-decoration: underline !important;
    color: blue !important;
    cursor: pointer;
}
.myProfile.v2 .carouselWrapper{
    background: none;
    background-color: #1e555c !important;
}
.myProfile.v2 .carouselWrapper::before{
    background-color: white;
}



@media screen and (max-width:1300px) {
    .myProfile.v2 {
        padding: 0px 50px;
    }

    .myProfile.v2 .wrapper {
        padding: 0px 0px;
        width: 100%;
    }

    .myProfile.v2 .media li {
        padding: 0 25px;
    }
}

@media screen and (max-width:996px) {
    .myProfile.v2 {
        padding: 0px 0px;
    }

    .myProfile.v2 .sectionBg {
        height: 360px;
    }

    .myProfile.v2 .imgBox img {
        width: 200px;
        height: 200px;
    }

    .myProfile.v2 .name {
        font-size: 38px;
    }

    .myProfile.v2 .profession {
        font-size: 24px;
    }

    .myProfile.v2 .saveProfile {
        font-size: 24px !important;
    }

    .myProfile.v2 .media li {
        padding: 0 18px;
    }
}

@media screen and (max-width:769px) {
    .myProfile.v2 {
        padding: 0px 0px;
    }

    .myProfile.v2 .sectionBg {
        height: 360px;
        background-size: cover !important;
        background-position: center !important;
        background-position: center !important;
        background-attachment: unset !important;
    }

    .myProfile.v2 .imgBox {
        min-height: 50px;
    }

    .myProfile.v2 .imgBox img {
        width: 160px;
        height: 160px;
    }

    .myProfile.v2 .name {
        font-size: 24px;
    }

    .myProfile.v2 .profession {
        font-size: 16px;
    }

    .headingButtons {
        font-size: 20px !important;
        padding: 10px 6px !important;
        line-height: 110% !important;
    }

    .myProfile.v2 .media {
        display: grid;
        grid-template-columns: repeat(3, auto);
        justify-content: unset !important;
    }

    .myProfile.v2 .bg-black {
        padding: 0 30px 90px 30px;
    }

    .myProfile.v2 .media {
        margin: 0 20px;
    }

    .myProfile.v2 .media li {
        margin-bottom: 30px;
        padding: 0px;
    }

    .myProfile.v2 .media li .circle {
        width: 60px;
        height: 60px;
    }

    .myProfile.v2 .media li .circle::after {
        width: 56px;
        height: 56px;
    }

    .myProfile.v2 .media li .circle svg {
        width: 24px;
        height: 24px;
    }

    .myProfile.v2 .media p {
        font-size: 14px;
        font-weight: 600;
    }

    .myProfile.v2 .share {
        width: 100%;
        padding: 0 20px;
    }

    .myProfile.v2 .share h6 {
        font-size: 20px;
    }

    .myProfile.v2 .share .item {
        margin: 0 10px;
    }

    .myProfile.v2 .share .item .iconBox {
        width: 36px;
        height: 36px;

    }

    .myProfile.v2 .share .item .iconBox svg {
        width: 25px;
        height: 25px;
        color: #000;
    }

    .myProfile.v2 .share .item .iconBox+p {
        font-size: 16px;
    }

    .myProfile.v2 .typingAnimation {
        font-size: 3em;
    }
}

