.navbarAuth {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 100px;
    margin-bottom: 40px;
    width: 100%;
}
.navbarAuth .navigator {
    font-weight: 500;
    font-size: 16px;
    color: #AAAAAA;
    width: auto;
}

.navbarAuth .navigator a {
    color: #3D86F7;
    text-decoration: underline;
}


@media screen and (max-width:1300px) {
    .navbarAuth {
        height: 80px;
        padding: 0 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

}

@media screen and (max-width:769px) {
    .navbarAuth {
        height: auto;
        padding: 20px;
        align-items: flex-start;
    }
    .navbarAuth .navigator {
        text-align: right;
        font-size: 14px;
    }
    .navbarAuth .navigator a {
       display: block;
    }
}