.chooseTemplate.myCards .card-item {
    margin-bottom: 15px;
}

.chooseTemplate.myCards .card-item .card-content {
    width: 100%;
    aspect-ratio: 1/1.2;
    overflow: hidden;
    margin-bottom: 15px;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.5s ease; 
}

.chooseTemplate.myCards .card-item .card-content:hover {
    transform: translateY(-10px);
    /* slight upward movement */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
    /* larger shadow on hover */
    transition: all 0.3s ease;
    background-color: #f9f9f9;
    /* optional color change */
}
.chooseTemplate.myCards .card-item img {
    transition: transform 0.3s ease, opacity 0.3s ease;
}


.chooseTemplate.myCards .card-item h2 {
    font-size: 24px;
    font-weight: 700;
    color: #303030;
    margin-bottom: 10px;
}

.chooseTemplate.myCards .card-item h3 {
    font-size: 18px;
    color: #303030;
    margin-bottom: 10px;
}

.chooseTemplate.myCards .card-item p {
    font-size: 14px;
    color: #AAAAAA;
    margin-bottom: 10px;
}

.chooseTemplate.myCards .sevtionHeader {
    font-weight: 900;
    margin: 30px 0;
}

.chooseTemplate.myCards .bg-main .card-content {
    background: var(--main);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 128px;
    width: 100%;
    font-weight: 800;
    border-radius: 0 !important;
    transition: all 0.2s linear;
}

.chooseTemplate.myCards .bg-main .card-content:hover {
    border-radius: 30px !important;
}

.chooseTemplate.myCards .bg-black::after {
    content: "";
    display: block;
    width: 700px;
    height: 60px;
    background-color: green;
    position: absolute;
    top: -45px;
    left: -50px;
    transform: rotate(5deg);
    background-color: #383838;
    z-index: 1;

}

.chooseTemplate.myCards .bg-black::before {
    content: "";
    display: block;
    width: 700px;
    height: 60px;
    background-color: blue;
    position: absolute;
    top: -45px;
    right: -50px;
    transform: rotate(-5deg);
    background-color: #383838;
    z-index: 1;

}

.chooseTemplate.myCards .sectionBg {
    height: 220px;
    background-attachment: unset !important;
}

.chooseTemplate.myCards .card-item.v2 * {
    color: black !important;
}

.chooseTemplate.myCards .card-item.v2 .bg-black {
    background-color: #fff;

    background-image: url("../../assets/images/template2-bg.jpg") !important;

    box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, .7);
}

.chooseTemplate.myCards .card-item.v2 .bg-black::after {
    background-color: #fff;
    box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, .7);
}

.chooseTemplate.myCards .card-item.v2 .bg-black::before {
    background-color: #fff;
    box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, .7);
}

.chooseTemplate.myCards .card-item.v3 * {
    color: white !important;
}

.chooseTemplate.myCards .card-item.v3 .bg-black {
    background-image: url("../../assets/images/template3-bg.jpg");
    background-repeat: no-repeat;
    background-position: end;
    background-size: cover;

    /* box-shadow: inset 0 0 0 1000px rgba(255,255,255,.7); */
}

.chooseTemplate.myCards .card-item.v3 .bg-black::after {
    background-image: url("../../assets/images/template3-bg.jpg");
    /* box-shadow: inset 0 0 0 1000px rgba(255,255,255,.7); */
}

.chooseTemplate.myCards .card-item.v3 .bg-black::before {
    background-image: url("../../assets/images/template3-bg.jpg");
    /* box-shadow: inset 0 0 0 1000px rgba(255,255,255,.7); */
}

.chooseTemplate.myCards .card-item.v3 h2 {
    color: black !important;
}

.chooseTemplate.myCards .imgBox {
    min-height: 30px;
}

.chooseTemplate.myCards .imgBox img {
    width: 120px;
    height: 120px;
    top: -130px !important;
}

.chooseTemplate.myCards .name {
    margin-top: 0px !important;
    font-weight: 800;
    font-size: 18px !important;
    color: white;
}

.chooseTemplate.myCards .profession {
    font-weight: 700;
    font-size: 12px !important;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.chooseTemplate.myCards .media li {
    padding: 0 5px;
}

.chooseTemplate.myCards .media li:hover .circle {
    scale: 1;
    background: linear-gradient(45deg, #E8C488, #8D6736) border-box !important;
}

.chooseTemplate.myCards .media li:hover .circle::after {
    background: #383838;
}

.chooseTemplate.myCards .media li:hover .circle svg {
    filter: grayscale(0%);
}


.chooseTemplate.myCards .media li .circle {
    border: 2px solid transparent;
    /* mask-composite: exclude; */
    width: 25px;
    height: 25px;
}

.chooseTemplate.myCards .media li .circle::after {
    width: 23px;
    height: 23px;
    content: "";
    background-color: #383838;
}

.chooseTemplate.myCards .media li .circle svg {
    height: 10px !important;
    width: 10px !important;
}

.chooseTemplate.myCards .media p {
    font-size: 10px;
}

.chooseTemplate.myCards .skeletons {
    color: white !important;
}

@media screen and (max-width:996px) {
    .chooseTemplate.myCards {
        padding-left: 30px;
        padding-right: 30px;
    }

    .chooseTemplate.myCards .card-item .card-content {
        width: 100% !important;
        overflow: hidden;
        margin-bottom: 15px;
        cursor: pointer;
    }
}

@media screen and (max-width:769px) {
    .chooseTemplate.myCards {
        padding-left: 20px;
        padding-right: 20px;
    }

    .chooseTemplate.myCards .card-item h2 {
        font-size: 18px;
    }

    .chooseTemplate.myCards .card-item h3 {
        font-size: 14px;
    }

    .chooseTemplate.myCards .card-item p {
        font-size: 10px;
    }

    .chooseTemplate.myCards .sectionBg {
        height: 150px;
    }

    .chooseTemplate.myCards .media li {
        margin-bottom: 0 !important;
    }

    .chooseTemplate.myCards .imgBox img {
        width: 80px;
        height: 80px;
        top: -90px !important;
    }
}

@media screen and (max-width:496px) {
    .chooseTemplate.myCards .card-item {
        padding-top: 0px !important;
    }

    .chooseTemplate.myCards .sectionBg {
        height: 70px;
        background-attachment: unset !important;
    }

    .chooseTemplate.myCards .imgBox img {
        width: 50px;
        height: 50px;
        top: -40px !important;
    }

    .chooseTemplate.myCards .bg-black::after {
        content: "";
        display: block;
        width: 700px;
        height: 30px;
        background-color: green;
        position: absolute;
        top: -12px;
        left: -50px;
        transform: rotate(2deg);
        background-color: #383838;
        z-index: 1;

    }

    .chooseTemplate.myCards .bg-black::before {
        content: "";
        display: block;
        width: 700px;
        height: 30px;
        background-color: blue;
        position: absolute;
        top: -12px;
        right: -50px;
        transform: rotate(-2deg);
        background-color: #383838;
        z-index: 1;

    }

    .chooseTemplate.myCards .name {
        margin-top: 0px !important;
        font-weight: 800;
        font-size: 12px !important;
        color: #FFFFFF;
        color: white !important;
        margin-bottom: 10px !important;
    }

    .chooseTemplate.myCards .profession {
        font-weight: 700;
        font-size: 8px !important;
        color: #FFFFFF;
        margin-bottom: 8px;
    }

    .chooseTemplate.myCards .imgBox {
        min-height: 15px;
    }

    .chooseTemplate.myCards .media li .circle {
        border: 2px solid transparent;
        /* mask-composite: exclude; */
        width: 18px;
        height: 18px;
    }

    .chooseTemplate.myCards .media li .circle::after {
        width: 16px;
        height: 16px;
        content: "";
        background-color: #383838;
    }

    .chooseTemplate.myCards .media li .circle svg {
        height: 6px !important;
        width: 6px !important;
    }

    .chooseTemplate.myCards .media li {
        padding: 0px;
    }

    .chooseTemplate.myCards .media p {
        font-size: 5px;
    }

    .chooseTemplate.myCards .media {
        margin: 0;
    }
}