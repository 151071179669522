.navbarMain {
    padding: 0 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    background-color: #111;
    color: white;
    position: sticky;
    top: 0;
    transition: all 0.2s linear;
    z-index: 99;
}

.navbarMain .menuIcon {
    display: none;
}

.navbarMain .logo {
    width: 133px;
    min-width: 133px;
    height: 40px;
}

.navbarMain:hover {
    top: 0;
}

.top-0 {
    top: 0;
}

.-top-24 {
    top: -60px;
    border-bottom: 2px solid var(--main);
}

.navlist li {
    margin: 0 20px;
    cursor: pointer;
}

.navlist li p {
    transition: all 0.1s linear;
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
}

.navlist li p:hover {
    opacity: 0.7;
    font-size: 16px;
}

.premium-btn {
    background: var(--main);
    color: white !important;
    padding: 8px 20px !important;
    transition: all 0.2s linear !important;
}

.premium-btn:hover {
    scale: 1.05;
}

.langSelect {
    color: white !important;
    border: none;
}

.langSelect {
    outline: none !important;
}

.langSelect legend {
    display: none !important;
}

.profile-box {
    background-color: white !important;
    padding: 8px 16px !important;
    border-radius: 5px !important;
    transition: all 0.2s linear !important;
}

.profile-box p {
    color: black !important;
    margin-left: 10px;
    font-weight: 600;
    font-size: 16px;
    color: #303030;
}

.profile-box:hover {
    scale: 1.1;
}

.MuiSelect-select.MuiSelect-standard.MuiInputBase-input {
    display: flex;
    align-items: center;
    height: auto !important;
    padding: 10px 24px 10px 4px !important;
    outline: none !important;
    border: none !important;
}

.MuiSelect-select.MuiSelect-standard.MuiInputBase-input svg {
    margin-right: 5px;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.Mui-focused::before {
    border-bottom: none !important;
}

.popupNavbar ul {
    padding: 5px 20px !important;
}

.popupNavbar ul li {
    margin: 15px 0;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.3s linear;
}

.popupNavbar ul li:hover {
    text-decoration: underline;
}

.popupNavbar ul li svg {
    margin-right: 10px;
}

.popupNavbar ul li svg path {
    fill: #FFFFFF !important;
}

.popupNavbar ul li.red {
    color: #ff595e;

}

.popupNavbar ul li.red svg path {
    fill: #ff595e !important;
}

@media screen and (max-width:1300px) {
    .navbarMain {
        padding: 0 50px;
    }

    .navbarMain .logo {
        width: 100px;
        min-width: 100px;
    }

    .navlist li {
        margin: 0 10px;
    }

    .navlist li p {
        font-size: 14px;
        ;
    }

    .navbarMain .profile-box {
        padding: 8px 12px !important;
    }

    .navbarMain .profile-box p {
        font-size: 14px;
    }

    .navbarMain .premium-btn {

        padding: 8px 16px !important;
    }
}

@media screen and (max-width:996px) {
    .navbarMain {
        padding: 0 30px;
    }

    .navbarMain .logo {
        width: 80px;
        min-width: 80px;
    }

    .navlist li {
        margin: 0 8px;
    }

    .navlist li p {
        font-size: 12px;
    }

    .navbarMain .profile-box {
        padding: 8px 10px !important;
    }

    .navbarMain .profile-box p {
        font-size: 12px;
    }

    .navbarMain .premium-btn {

        padding: 8px 14px !important;
    }
}

@media screen and (max-width:769px) {
    .navbarMain {
        padding: 0 20px;
    }

    .navbarMain .logo {
        width: 133px;
        min-width: 133px;
    }

    .navbarMain .menuIcon {
        display: block;
        min-width: 40px;
    }

    .navbarMain .navlist {
        display: none;
    }

    .navbarMain .profile-box {
        padding: 8px 0px !important;
        min-width: 40px;
    }

    .navbarMain .profile-box p {
        display: none;
    }

    .navbarMain .premium-btn {
        display: none;
    }
}