:root {
    --border-width: 2px;
    --border-radius: 22px;
    --color-1: #f19f39;
    --color-2: #de222c;
}

.myProfile {
    padding: 0px 100px;
}

.myProfile .bizzcard {
    padding-top: 0 !important;
}

.myProfile .sectionBg {
    height: 460px;
    width: 100%;
    background-size: cover !important;
    background-position: center !important;
    background-position: center !important;
    /* background-attachment: fixed !important; */
}

.myProfile .wrapper {
    padding: 0px 100px;
}

.myProfile .wrapper-child {
    margin-top: 0px;
    width: 100%;
    overflow-x: hidden;
}
.myProfile .imgBox {
    width: 100%;
    position: relative;
    z-index: 9;
    min-height: 120px;
}
.myProfile .imgBox img {
    display: block;
    width: 240px;
    height: 240px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -130px !important;
    border-radius: 50%;
    object-fit: cover;
}

.myProfile .name {
    margin-top: 30px;
    font-weight: 600;
    font-size: 48px;
    color: #FFFFFF;
    margin-bottom: 12px;
}

.myProfile .profession {
    font-weight: 500;
    font-size: 30px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 50px;
}
.myProfile .media li a {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.myProfile .media li .circle {
    border: 2px solid transparent;
    background: linear-gradient(45deg, #E8C488, #8D6736) border-box;
    /* mask-composite: exclude; */
    width: 54px;
    height: 54px;
    border-radius: 50%;
    position: relative;
    transition: all 0.2s linear;
}
.myProfile .media li .circle::after {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    content: "";
}
.myProfile .rdw-editor-toolbar {
    display: none !important;
}
.myProfile .card-body {
    border-radius: 5px !important;
}

.myProfile .media li .circle svg {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    transition: all 0.2s linear;
}
.myProfile .media p {
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 10px;
}

.myProfile .typingText {
    text-align: center;
    color: white;
    margin: "40px 0 20px";
    border-bottom: "2px solid white";
    padding-bottom: 15px;
}

.myProfile .saveProfile {
    padding: 10px 20px;
    background: var(--main);
    font-weight: 700;
    font-size: 30px;
    color: #FFFFFF;
    text-transform: unset;
    border-radius: 10px;
    margin: 40px 0 60px;
}

.myProfile .aboutMe {
    font-weight: 700;
    font-size: 30px;
    color: #FFFFFF;
    margin-bottom: 10px;
}
