* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: sans-serif;
}

body::-webkit-scrollbar {
  width: 15px;
  cursor: pointer;
}


body::-webkit-scrollbar-track {
  background: white;
}

body::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: var(--main);
  border-radius: 10px;
}