.staisticsOfCards{
    padding: 30px 0;
}
.staisticsOfCards .paper {
    width: 100%;
    padding: 20px;
    margin: 0 0 30px 0;
    position: relative;
}

.staisticsOfCards .iconBox{
    position: absolute;
    right: -20px;
    top: -20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.staisticsOfCards .iconBox.redBg{
    background-color: #d90429;
}
.staisticsOfCards .iconBox.greenBg{
    background-color: #38b000;
}

.staisticsOfCards .iconBox img {
    width: 25px;
}

.staisticsOfCards .paper h1{
    font-size: 50px !important;
    color: #212529;
    margin-top: 15px;
}
.staisticsOfCards .paper h3{
    color: #8d99ae;
}
.statTitles{
    margin: 0 0 20px 0;
}
.pieCompnonent .labels div{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 12px;
}
.pieCompnonent .labels img{
    width: 30px;
    height: 30px;
}
@media screen and (max-width:769px) {
    .staisticsOfCards{
        padding: 30px 20px;
    }
    .p-0-onMObile{
        padding: 0 !important;
    }
}