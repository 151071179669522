/* CardForm.css */
.formContainer {
    max-width: 400px;
    margin: 40px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.formContainer .textField {
    margin-bottom: 15px;
}

.formContainer .submitButton {
    margin-top: 20px;
}

.formContainer .infoText {
    margin-top: 20px;
    color: #666;
}

.formContainer .linkText {
    margin-top: 10px;
}
.formContainer .logo{
    margin: 15px auto;
    width: 200px;
    display: block;
}