.heroWrapper {
    background: url("../../assets/images/heroBg.png");
    background-position: center;
    background-size: cover;
    height: 80vh;
}

.hero {
    width: 100%;
    height: 100%;
    padding: 140px 100px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-color: rgba(0, 0, 0, .5);
}

.headerHero {
    font-weight: 700 !important;
    font-size: 48px !important;
    width: 60%;
    color: #FFFFFF;
}

.typographyhero {
    margin: 20px 0 !important;
    opacity: 0.6;
}

.buttonHero {
    background-color: white !important;
    color: #303030 !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    padding: 12px 26px !important;
    margin-top: 10px !important;
}

@media screen and (max-width:1300px) {
    .hero {
        padding: 140px 50px;
    }
}


@media screen and (max-width:769px) {
    .heroWrapper {
        height: 90vh;
        text-align: center;
    }

    .hero {
        padding: 20px 30px !important;
        justify-content: center;
    }

    .headerHero {
        font-size: 24px !important;
        width: 100%;
        /* margin-bottom: 20vh !important; */
    }
    .typographyhero {
        font-size: 16px !important;
        margin: 20px 0 !important;
        opacity: 0.6;
    }
    .buttonHero {
        font-size: 12px !important;
        padding: 12px 22px !important;
        margin-right: auto !important;
        margin-left: auto !important;
    }

}
@media screen and (max-height:700px) {
    .heroWrapper {
        height: 100vh;
    }
}